// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General Body Style */
:root {
    --primary-color: #780000;
    --secondary-color: #C1121F;
    --background-color: #FDF0D5;
    --accent-color: #003049;
    --highlight-color: #669BBC;
}

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #FDF0D5;
}

/* Slick Carousel Overrides */
.slick-slider {
    margin: 0 auto;
    max-width: 1200px;
}

.slick-prev:before,
.slick-next:before {
    color: #1976d2;
    /* Customize arrow colors */
    font-size: 24px;
}

.slick-dots {
    bottom: -20px;
    /* Position dots closer to slider */
}

.slick-dots li button:before {
    color: #ccc;
    font-size: 12px;
    transition: color 0.3s;
}

.slick-dots li.slick-active button:before {
    color: #1976d2;
    /* Highlight active dot */
}

/* Card Hover Effects */
.slick-slide>div {
    padding: 10px;
}

.slick-slide:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,2BAA2B;IAC3B,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,SAAS;IACT,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA,6BAA6B;AAC7B;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;;IAEI,cAAc;IACd,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI,WAAW;IACX,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA,uBAAuB;AACvB;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,0BAA0B;AAC9B","sourcesContent":["/* General Body Style */\r\n:root {\r\n    --primary-color: #780000;\r\n    --secondary-color: #C1121F;\r\n    --background-color: #FDF0D5;\r\n    --accent-color: #003049;\r\n    --highlight-color: #669BBC;\r\n}\r\n\r\nbody {\r\n    margin: 0;\r\n    font-family: Arial, sans-serif;\r\n    background-color: #FDF0D5;\r\n}\r\n\r\n/* Slick Carousel Overrides */\r\n.slick-slider {\r\n    margin: 0 auto;\r\n    max-width: 1200px;\r\n}\r\n\r\n.slick-prev:before,\r\n.slick-next:before {\r\n    color: #1976d2;\r\n    /* Customize arrow colors */\r\n    font-size: 24px;\r\n}\r\n\r\n.slick-dots {\r\n    bottom: -20px;\r\n    /* Position dots closer to slider */\r\n}\r\n\r\n.slick-dots li button:before {\r\n    color: #ccc;\r\n    font-size: 12px;\r\n    transition: color 0.3s;\r\n}\r\n\r\n.slick-dots li.slick-active button:before {\r\n    color: #1976d2;\r\n    /* Highlight active dot */\r\n}\r\n\r\n/* Card Hover Effects */\r\n.slick-slide>div {\r\n    padding: 10px;\r\n}\r\n\r\n.slick-slide:hover {\r\n    transform: scale(1.05);\r\n    transition: transform 0.3s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
