const cardConfigurations = {
    birthday: [
        {
            id: "birthday1",
            title: "Birthday Template 1",
            imageUrl: "/assets/templates/birthday/card1.png",
            textStyles: {
                fontSize: "20px",
                color: "#000",
                fontFamily: "font-serif",
            },
            textareaStyles: {
                top: "60%",
                left: "7%",
                width: "40%",
                height: "35%",
            },
        },
        {
            id: "birthday2",
            title: "Birthday Template 2",
            imageUrl: "/assets/templates/birthday/card2.png",
            textStyles: {
                position: "absolute",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
            textareaStyles: {
                top: "30%",
                left: "10%",
                width: "80%",
                height: "30%",
            },
        },
        {
            id: "birthday3",
            title: "Birthday Template 3",
            imageUrl: "/assets/templates/birthday/card3.png",
            textStyles: {
                position: "absolute",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
            textareaStyles: {
                top: "45%",
                left: "17%",
                width: "70%",
                height: "30%",
            },
        },
        {
            id: "birthday4",
            title: "Birthday Template 4",
            imageUrl: "/assets/templates/birthday/card4.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
        {
            id: "birthday5",
            title: "Birthday Template 5",
            imageUrl: "/assets/templates/birthday/card5.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
        {
            id: "birthday6",
            title: "Birthday Template 6",
            imageUrl: "/assets/templates/birthday/card6.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
        {
            id: "birthday7",
            title: "Birthday Template 7",
            imageUrl: "/assets/templates/birthday/card7.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
        {
            id: "birthday8",
            title: "Birthday Template 8",
            imageUrl: "/assets/templates/birthday/card8.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
        {
            id: "birthday9",
            title: "Birthday Template 9",
            imageUrl: "/assets/templates/birthday/card9.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
        {
            id: "birthday10",
            title: "Birthday Template 10",
            imageUrl: "/assets/templates/birthday/card10.png",
            textStyles: {
                position: "absolute",
                top: "35%",
                left: "20%",
                right: "20%",
                fontSize: "20px",
                color: "#000",
                fontFamily: "Georgia, serif",
            },
        },
    ],
    wedding: [
        {
            id: "wedding1",
            title: "Wedding Template 1",
            imageUrl: "/assets/templates/birthday/card3.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "wedding2",
            title: "Wedding Template 2",
            imageUrl: "/assets/templates/birthday/card4.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        }
    ],
    anniversary: [
        {
            id: "anniversary1",
            title: "Anniversary Template 1",
            imageUrl: "/assets/templates/anniversary/card1.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary2",
            title: "Anniversary Template 2",
            imageUrl: "/assets/templates/anniversary/card2.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary3",
            title: "Anniversary Template 3",
            imageUrl: "/assets/templates/anniversary/card3.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary4",
            title: "Anniversary Template 4",
            imageUrl: "/assets/templates/anniversary/card4.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary5",
            title: "Anniversary Template 5",
            imageUrl: "/assets/templates/anniversary/card5.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary6",
            title: "Anniversary Template 6",
            imageUrl: "/assets/templates/anniversary/card6.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary7",
            title: "Anniversary Template 7",
            imageUrl: "/assets/templates/anniversary/card7.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary8",
            title: "Anniversary Template 8",
            imageUrl: "/assets/templates/anniversary/card8.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary9",
            title: "Anniversary Template 9",
            imageUrl: "/assets/templates/anniversary/card9.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "anniversary10",
            title: "Anniversary Template 10",
            imageUrl: "/assets/templates/anniversary/card10.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
    ],
    christmas: [
        {
            id: "christmas1",
            title: "Christmas Template 1",
            imageUrl: "/assets/templates/christmas/card1.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas2",
            title: "Christmas Template 2",
            imageUrl: "/assets/templates/christmas/card2.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas3",
            title: "Christmas Template 3",
            imageUrl: "/assets/templates/christmas/card3.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas4",
            title: "Christmas Template 4",
            imageUrl: "/assets/templates/christmas/card4.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas5",
            title: "Christmas Template 5",
            imageUrl: "/assets/templates/christmas/card5.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas6",
            title: "Christmas Template 6",
            imageUrl: "/assets/templates/christmas/card6.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas7",
            title: "Christmas Template 7",
            imageUrl: "/assets/templates/christmas/card7.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas8",
            title: "Christmas Template 8",
            imageUrl: "/assets/templates/christmas/card8.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas9",
            title: "Christmas Template 9",
            imageUrl: "/assets/templates/christmas/card9.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
        {
            id: "christmas10",
            title: "Christmas Template 10",
            imageUrl: "/assets/templates/christmas/card10.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
    ],
    newyear: [
        {
            id: "newyear1",
            title: "Newyear Template 1",
            imageUrl: "/assets/templates/birthday/card7.png",
            textStyles: {
                position: "absolute",
                top: "10%",
                left: "20%",
                fontSize: "22px",
                color: "#ff0",
                fontFamily: "Courier New, monospace",
            },
        },
    ],
};

export default cardConfigurations;
