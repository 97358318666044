import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const LoaderScreen = ({ onFinish }) => {
    useEffect(() => {
        const timer = setTimeout(onFinish, 5000); // Finish after 5 seconds
        return () => clearTimeout(timer); // Cleanup the timer
    }, [onFinish]);

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                zIndex: 9999,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress color="primary" sx={{ mb: 2 }} />
            <Typography variant="h6" color="primary" sx={{ mb: 4 }}>
                Generating your personalized wish...
            </Typography>
            {/* Google AdSense Ad */}
            <Box
                sx={{
                    width: "300px",
                    height: "250px",
                    border: "1px solid #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="body2" color="textSecondary">
                    [Google AdSense Ad Placeholder]
                </Typography>
                {/* Replace this with actual AdSense script */}
            </Box>
        </Box>
    );
};

export default LoaderScreen;
