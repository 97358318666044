import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, MenuItem, FormControl, Select, InputLabel, Paper, Stepper, Step, StepLabel } from "@mui/material";
import { useLocation } from "react-router-dom";
import LoaderScreen from "../components/Shared/LoaderScreen";
import WishFormSlider from "../components/WishForm/WishFormSlider";
import WishPopup from "../components/Popup/WishPopup";
import { generateWish } from "../utils/api";
import cardConfigurations from "../data/cardConfigurations";

const steps = ["Basic Information", "Wish Details", "Review & Generate"];

const WishFormPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialOccasion = queryParams.get("category") || "birthday";

    const [formData, setFormData] = useState({
        sender_name: "",
        occasion: initialOccasion,
        recipient_name: "",
        theme: "Funny",
        relation: "",
        word_count: 50,
        language: "English",
    });

    const [wish, setWish] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        // Update the occasion if it changes from the query parameters
        setFormData((prev) => ({
            ...prev,
            occasion: initialOccasion,
        }));
    }, [initialOccasion]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === "word_count" ? parseInt(value, 10) : value,
        }));
    };

    const validateStep = () => {
        if (activeStep === 0) {
            return formData.sender_name.trim() !== "" && formData.occasion.trim() !== "";
        }
        if (activeStep === 1) {
            return (
                formData.recipient_name.trim() !== "" &&
                formData.theme.trim() !== "" &&
                formData.relation.trim() !== "" &&
                formData.word_count > 0 &&
                formData.language.trim() !== ""
            );
        }
        return true; // No validation for the review step
    };

    const handleNext = () => {
        if (validateStep()) {
            if (activeStep < steps.length - 1) {
                setActiveStep((prev) => prev + 1);
            }
        } else {
            alert("Please fill all required fields before proceeding.");
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prev) => prev - 1);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        const loaderPromise = new Promise((resolve) => setTimeout(resolve, 5000));
        try {
            const [response] = await Promise.all([generateWish(formData), loaderPromise]);

            setWish(response);
            setFormSubmitted(true);
        } catch (error) {
            console.error("Error generating wish:", error);
            setWish("Failed to generate wish. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRegenerate = () => {
        setWish(null);
        setFormSubmitted(false);
        setActiveStep(0);
    };

    const handleSelectTemplate = (template) => {
        setSelectedCard(template);
        setIsPopupOpen(true);
    };

    const occasionTemplates = cardConfigurations[formData.occasion.toLowerCase()] || [];

    return (
        <>
            {isLoading && <LoaderScreen />}
            <Box
                sx={{
                    minHeight: "100vh",
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Hero Section */}
                <Box
                    sx={{
                        textAlign: "center",
                        py: 6,
                        backgroundColor: "primary.main",
                        color: "primary.contrastText",
                    }}
                >
                    <Typography variant="h2" fontWeight="bold" gutterBottom>
                        Generate Your Personalized Wish
                    </Typography>
                    <Typography variant="h6" sx={{ maxWidth: "800px", mx: "auto" }}>
                        Craft meaningful and beautiful wishes for any occasion. Let’s make someone’s day special!
                    </Typography>
                </Box>

                {/* Form Section */}
                {!formSubmitted ? (
                    <Paper
                        elevation={3}
                        sx={{
                            p: 4,
                            maxWidth: "600px",
                            width: "100%",
                            mx: "auto",
                            mt: 3,
                            borderRadius: "10px",
                        }}
                    >
                        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <>
                                <TextField
                                    fullWidth
                                    label="Sender Name"
                                    name="sender_name"
                                    value={formData.sender_name}
                                    onChange={handleChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel>Occasion</InputLabel>
                                    <Select name="occasion" value={formData.occasion} onChange={handleChange} required>
                                        {Object.keys(cardConfigurations).map((occasion) => (
                                            <MenuItem key={occasion} value={occasion}>
                                                {occasion.charAt(0).toUpperCase() + occasion.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <TextField
                                    fullWidth
                                    label="Recipient's Name"
                                    name="recipient_name"
                                    value={formData.recipient_name}
                                    onChange={handleChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel>Theme</InputLabel>
                                    <Select name="theme" value={formData.theme} onChange={handleChange}>
                                        <MenuItem value="Funny">Funny</MenuItem>
                                        <MenuItem value="Thoughtful">Thoughtful</MenuItem>
                                        <MenuItem value="Formal">Formal</MenuItem>
                                        <MenuItem value="Casual">Casual</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Relation"
                                    name="relation"
                                    value={formData.relation}
                                    onChange={handleChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Word Count"
                                    name="word_count"
                                    type="number"
                                    value={formData.word_count}
                                    onChange={handleChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Language</InputLabel>
                                    <Select name="language" value={formData.language} onChange={handleChange} required>
                                        <MenuItem value="English">English</MenuItem>
                                        <MenuItem value="Spanish">Spanish</MenuItem>
                                        <MenuItem value="French">French</MenuItem>
                                        <MenuItem value="Hindi">Hindi</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        {activeStep === 2 && (
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    Review Your Details:
                                </Typography>
                                <Typography><strong>Sender Name:</strong> {formData.sender_name}</Typography>
                                <Typography><strong>Occasion:</strong> {formData.occasion}</Typography>
                                <Typography><strong>Recipient Name:</strong> {formData.recipient_name}</Typography>
                                <Typography><strong>Theme:</strong> {formData.theme}</Typography>
                                <Typography><strong>Relation:</strong> {formData.relation}</Typography>
                                <Typography><strong>Word Count:</strong> {formData.word_count}</Typography>
                                <Typography><strong>Language:</strong> {formData.language}</Typography>
                            </Box>
                        )}
                        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="outlined"
                                color="primary"
                                sx={{ borderRadius: "20px" }}
                            >
                                Back
                            </Button>
                            {activeStep === steps.length - 1 ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    sx={{ borderRadius: "20px" }}
                                >
                                    Generate Wish
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    sx={{ borderRadius: "20px" }}
                                >
                                    Next
                                </Button>
                            )}
                        </Box>
                    </Paper>
                ) : (
                    <Box sx={{ maxWidth: "800px", mx: "auto", textAlign: "center", mt: 4 }}>
                        <Typography variant="h6" color="text.primary" sx={{ whiteSpace: "pre-wrap", mb: 4 }}>
                            {wish}
                        </Typography>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleRegenerate}
                            sx={{
                                textTransform: "none",
                                borderRadius: "30px",
                                px: 4,
                                py: 1.5,
                                mb: 4,
                            }}
                        >
                            Regenerate Wish
                        </Button>
                        <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                            Select a Template
                        </Typography>
                        <WishFormSlider templates={occasionTemplates} onSelectTemplate={handleSelectTemplate} />
                    </Box>
                )}

                {/* Popup for Template Preview */}
                {selectedCard && (
                    <WishPopup
                        selectedCard={selectedCard}
                        wish={wish}
                        isOpen={isPopupOpen}
                        onClose={() => setIsPopupOpen(false)}
                    />
                )}
            </Box>
        </>
    );
};

export default WishFormPage;
