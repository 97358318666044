import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";

const Footer = () => (
    <Box
        sx={{
            width: "100%",
            textAlign: "center",
            py: 4,
            px: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
        }}
    >
        <Grid container justifyContent="center" spacing={2}>
            {/* Links Section */}
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Quick Links
                </Typography>
                <Box>
                    <Link href="/" underline="none" color="primary.contrastText" sx={{ display: "block", mb: 1 }}>
                        Home
                    </Link>
                    <Link href="/about" underline="none" color="primary.contrastText" sx={{ display: "block", mb: 1 }}>
                        About Us
                    </Link>
                    <Link href="/contact" underline="none" color="primary.contrastText" sx={{ display: "block" }}>
                        Contact Us
                    </Link>
                </Box>
            </Grid>

            {/* About Section */}
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Greetings & Wishes
                </Typography>
                <Typography variant="body2" color="primary.contrastText" sx={{ px: 2 }}>
                    Greetings & Wishes is your one-stop destination to create and personalize greeting cards for every occasion. 
                    Make your celebrations special with just a few clicks!
                </Typography>
            </Grid>

            {/* Social Media Section */}
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Follow Us
                </Typography>
                <Box>
                    <Link
                        href="https://facebook.com"
                        target="_blank"
                        underline="none"
                        color="primary.contrastText"
                        sx={{ mx: 1 }}
                    >
                        Facebook
                    </Link>
                    <Link
                        href="https://twitter.com"
                        target="_blank"
                        underline="none"
                        color="primary.contrastText"
                        sx={{ mx: 1 }}
                    >
                        Twitter
                    </Link>
                    <Link
                        href="https://instagram.com"
                        target="_blank"
                        underline="none"
                        color="primary.contrastText"
                        sx={{ mx: 1 }}
                    >
                        Instagram
                    </Link>
                </Box>
            </Grid>
        </Grid>

        {/* Copyright Section */}
        <Box sx={{ mt: 4, borderTop: "1px solid rgba(255, 255, 255, 0.2)", pt: 2 }}>
            <Typography variant="body2" color="primary.contrastText">
                &copy; {new Date().getFullYear()} Greetings & Wishes. All Rights Reserved.
            </Typography>
        </Box>
    </Box>
);

export default Footer;
