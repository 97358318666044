import React, { useState, useEffect, useCallback } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";

const WishPopup = ({ selectedCard, wish, isOpen, onClose }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedWish, setEditedWish] = useState("");
    const [dynamicFontSize, setDynamicFontSize] = useState("");

    // Memoize the calculateFontSize function for stability
    const calculateFontSize = useCallback(
        (text) => {
            const cardPreview = document.getElementById("card-preview");
            if (!cardPreview) return;

            const previewWidth = cardPreview.offsetWidth;
            const previewHeight = cardPreview.offsetHeight;

            const {
                top: topPercent,
                left: leftPercent,
                width: widthPercent,
                height: heightPercent,
            } = selectedCard.textareaStyles;

            const left = (parseFloat(leftPercent) / 100) * previewWidth;
            const right = ((100 - (parseFloat(leftPercent) + parseFloat(widthPercent))) / 100) * previewWidth;
            const width = (parseFloat(widthPercent) / 100) * previewWidth;
            const top = (parseFloat(topPercent) / 100) * previewHeight;
            const bottom = ((100 - (parseFloat(topPercent) + parseFloat(heightPercent))) / 100) * previewHeight;
            const height = (parseFloat(heightPercent) / 100) * previewHeight;

            // Effective area calculation
            const effectiveWidth = previewWidth - left - right;
            const effectiveHeight = previewHeight - top - bottom;

            // Ensure the calculated area is bounded by the actual container size
            const boundedWidth = Math.min(width, effectiveWidth);
            const boundedHeight = Math.min(height, effectiveHeight);

            // Calculate the area of the available text space
            const charCount = text.length;
            const area = boundedWidth * boundedHeight;

            // Estimate font size based on the area and character count
            const fontSize = Math.sqrt(area / charCount) * 0.95;

            // Clamp the font size to avoid being too small or large
            const clampedFontSize = Math.max(10, Math.min(fontSize, parseInt(selectedCard.textStyles.fontSize, 10)));
            setDynamicFontSize(`${clampedFontSize}px`);
        },
        [selectedCard]
    );

    // Recalculate font size when the card or wish changes
    useEffect(() => {
        if (isOpen && selectedCard) {
            setEditedWish(wish);
            calculateFontSize(wish);
        }
    }, [selectedCard, wish, isOpen, calculateFontSize]);

    const handleTextChange = (e) => {
        const newText = e.target.value;
        setEditedWish(newText);
        calculateFontSize(newText);
    };

    const handleEditToggle = () => {
        setIsEditing((prev) => !prev);
    };

    const cropCanvas = (canvas) => {
        const context = canvas.getContext("2d");
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        let top = canvas.height;
        let bottom = 0;
        let left = canvas.width;
        let right = 0;

        for (let y = 0; y < canvas.height; y++) {
            for (let x = 0; x < canvas.width; x++) {
                const alpha = imageData.data[(y * canvas.width + x) * 4 + 3];
                if (alpha > 0) {
                    if (y < top) top = y;
                    if (y > bottom) bottom = y;
                    if (x < left) left = x;
                    if (x > right) right = x;
                }
            }
        }

        const width = right - left;
        const height = bottom - top;

        const croppedCanvas = document.createElement("canvas");
        const croppedContext = croppedCanvas.getContext("2d");

        croppedCanvas.width = width;
        croppedCanvas.height = height;

        croppedContext.drawImage(canvas, left, top, width, height, 0, 0, width, height);

        return croppedCanvas;
    };

    const handleDownload = async () => {
        const cardElement = document.getElementById("card-preview");
        if (cardElement) {
            const canvas = await html2canvas(cardElement, {
                width: 1080,
                height: 1920,
                backgroundColor: null,
            });

            const croppedCanvas = cropCanvas(canvas);

            const link = document.createElement("a");
            link.download = "custom-card.png";
            link.href = croppedCanvas.toDataURL("image/png");
            link.click();
        }
    };

    const handleClose = () => {
        setIsEditing(false);
        setEditedWish("");
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>Card Preview</DialogTitle>
            <DialogContent>
                <Box
                    id="card-preview"
                    sx={{
                        width: "720px",
                        height: "1280px",
                        backgroundImage: `url(${selectedCard.imageUrl})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                        mx: "auto",
                    }}
                >
                    {isEditing ? (
                        <textarea
                            id="wish-textarea"
                            style={{
                                ...selectedCard.textareaStyles,
                                fontSize: dynamicFontSize,
                                position: "absolute",
                                backgroundColor: "rgba(255, 255, 255, 0.85)",
                                border: "1px solid rgba(0,0,0,0.2)",
                                borderRadius: "4px",
                                resize: "none",
                                overflow: "hidden",
                                whiteSpace: "pre-wrap",
                                outline: "none",
                                padding: "2%",
                            }}
                            value={editedWish}
                            onChange={handleTextChange}
                        />
                    ) : (
                        <Typography
                            style={{
                                ...selectedCard.textareaStyles,
                                fontSize: dynamicFontSize,
                                position: "absolute",
                                whiteSpace: "pre-wrap",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "top",
                                justifyContent: "center",
                                textAlign: "left",
                            }}
                        >
                            {editedWish || wish}
                        </Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={handleEditToggle}
                    sx={{ textTransform: "none", borderRadius: "20px" }}
                >
                    {isEditing ? "Save" : "Edit"}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FileDownloadIcon />}
                    onClick={handleDownload}
                    sx={{ textTransform: "none", borderRadius: "20px" }}
                >
                    Download
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                    sx={{ textTransform: "none", borderRadius: "20px" }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WishPopup;
