import React from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import TeamworkIcon from "@mui/icons-material/Groups";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MissionIcon from "@mui/icons-material/Flag";

const AboutPage = () => {
    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "background.default",
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    textAlign: "center",
                    py: 6,
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                }}
            >
                <Typography variant="h2" fontWeight="bold" gutterBottom>
                    About Us
                </Typography>
                <Typography variant="h6" sx={{ maxWidth: "800px", mx: "auto" }}>
                    Creating moments that matter, one greeting card at a time.
                </Typography>
            </Box>

            {/* Main Content */}
            <Box sx={{ px: 3, py: 6 }}>
                <Grid container spacing={4} sx={{ maxWidth: "1200px", mx: "auto" }}>
                    {/* Mission Section */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
                            <Box textAlign="center" sx={{ mb: 2 }}>
                                <MissionIcon fontSize="large" color="secondary" />
                            </Box>
                            <Typography variant="h4" fontWeight="bold" gutterBottom color="primary.main">
                                Our Mission
                            </Typography>
                            <Typography variant="body1">
                                Our mission is to make it easy and fun for people to create personalized, meaningful
                                greeting cards for any occasion. Whether it’s a birthday, wedding, or a simple
                                congratulations, we believe everyone should have access to heartfelt messages and
                                beautiful designs.
                            </Typography>
                        </Paper>
                    </Grid>

                    {/* How It Works Section */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
                            <Box textAlign="center" sx={{ mb: 2 }}>
                                <WorkOutlineIcon fontSize="large" color="secondary" />
                            </Box>
                            <Typography variant="h4" fontWeight="bold" gutterBottom color="primary.main">
                                How It Works
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                We’ve designed our platform to be simple and intuitive:
                            </Typography>
                            <ol>
                                <li>Fill out the form with details like occasion, recipient name, and theme.</li>
                                <li>Generate a personalized message in seconds.</li>
                                <li>Choose a design template from our collection.</li>
                                <li>Customize your card, download it, or share it online!</li>
                            </ol>
                        </Paper>
                    </Grid>

                    {/* Team Section */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
                            <Box textAlign="center" sx={{ mb: 2 }}>
                                <TeamworkIcon fontSize="large" color="secondary" />
                            </Box>
                            <Typography variant="h4" fontWeight="bold" gutterBottom color="primary.main">
                                Meet the Team
                            </Typography>
                            <Typography variant="body1">
                                We are a group of designers, developers, and creatives passionate about bringing people
                                closer through heartfelt messages. Our team is committed to making your experience as
                                enjoyable as possible.
                            </Typography>
                        </Paper>
                    </Grid>

                    {/* Stay Connected Section */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 4, textAlign: "center", borderRadius: "10px" }}>
                            <Typography variant="h4" fontWeight="bold" gutterBottom color="primary.main">
                                Stay Connected
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                Follow us on our social channels or reach out with any questions or feedback!
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ textTransform: "none", borderRadius: "20px", mx: 1 }}
                                href="/contact"
                            >
                                Contact Us
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ textTransform: "none", borderRadius: "20px", mx: 1 }}
                                href="https://www.facebook.com"
                                target="_blank"
                            >
                                Facebook
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ textTransform: "none", borderRadius: "20px", mx: 1 }}
                                href="https://www.twitter.com"
                                target="_blank"
                            >
                                Twitter
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default AboutPage;
