import React from "react";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";

const categories = [
    { name: "Birthday", image: "/assets/templates/landingpage/birthdays.png" },
    { name: "Wedding", image: "/assets/templates/landingpage/weddings.png" },
    { name: "Anniversary", image: "/assets/templates/landingpage/anniversary.png" },
    { name: "Christmas", image: "/assets/templates/landingpage/christmas.png" },
    { name: "NewYear", image: "/assets/templates/landingpage/new-year.png" },
];

const Home = () => {
    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "background.default",
            }}
        >
            {/* Hero Section */}
            <Box
                sx={{
                    textAlign: "center",
                    pt: 8,
                    pb: 6,
                    px: 2,
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                }}
            >
                <Typography variant="h2" fontWeight="bold" gutterBottom>
                    Create Stunning Greeting Cards
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                    Personalize wishes for every occasion in just a few clicks!
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mr: 2,
                        px: 4,
                        py: 1.5,
                        borderRadius: "30px",
                        textTransform: "none",
                        fontSize: "16px",
                    }}
                    href="/wishform"
                >
                    Get Started
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: "30px",
                        textTransform: "none",
                        fontSize: "16px",
                        borderWidth: "2px",
                        "&:hover": {
                            borderWidth: "2px",
                        },
                    }}
                    href="#categories"
                >
                    Learn More
                </Button>
            </Box>

            {/* Ad Placeholder Below Hero */}
            <Box
                sx={{
                    width: "100%",
                    textAlign: "center",
                    py: 2,
                    backgroundColor: "background.paper",
                    border: "1px dashed #ccc",
                    my: 4,
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    Ad Placeholder (728x90)
                </Typography>
            </Box>

            {/* Categories Section */}
            <Box
                id="categories"
                sx={{
                    maxWidth: "1200px",
                    mx: "auto",
                    px: 2,
                }}
            >
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    textAlign="center"
                    color="primary.main"
                    gutterBottom
                    sx={{ mb: 4 }}
                >
                    Explore Categories
                </Typography>
                <Grid container spacing={4}>
                    {categories.map((category, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper
                                elevation={4}
                                sx={{
                                    overflow: "hidden",
                                    borderRadius: "10px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    backgroundColor: "background.paper",
                                    transition: "transform 0.3s, box-shadow 0.3s",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                        boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
                                    },
                                }}
                                onClick={() => {
                                    window.location.href = `/wishform?category=${category.name.toLowerCase()}`;
                                }}
                            >
                                <Box
                                    component="img"
                                    src={category.image}
                                    alt={category.name}
                                    sx={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                    }}
                                />
                                <Box
                                    sx={{
                                        py: 2,
                                        backgroundColor: "secondary.main",
                                        color: "secondary.contrastText",
                                    }}
                                >
                                    <Typography variant="h6" fontWeight="bold">
                                        {category.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Home;
