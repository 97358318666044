import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Button, Paper } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Simulate form submission (replace with actual API call in the future)
        console.log("Form Data Submitted:", formData);
        setFormSubmitted(true);
    };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "background.default",
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    textAlign: "center",
                    py: 6,
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                }}
            >
                <Typography variant="h2" fontWeight="bold" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="h6" sx={{ maxWidth: "800px", mx: "auto" }}>
                    Have questions or feedback? We'd love to hear from you!
                </Typography>
            </Box>

            {/* Main Content */}
            <Box sx={{ px: 3, py: 6 }}>
                <Grid container spacing={4} sx={{ maxWidth: "1200px", mx: "auto" }}>
                    {/* Contact Form */}
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
                            <Typography variant="h4" fontWeight="bold" gutterBottom color="primary.main">
                                Send Us a Message
                            </Typography>
                            {formSubmitted ? (
                                <Typography variant="body1" color="success.main">
                                    Thank you for reaching out! We'll get back to you soon.
                                </Typography>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Your Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Your Email"
                                                name="email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Your Message"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                fullWidth
                                                sx={{
                                                    textTransform: "none",
                                                    borderRadius: "30px",
                                                    py: 1.5,
                                                    fontSize: "16px",
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Paper>
                    </Grid>

                    {/* Contact Information */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
                            <Typography variant="h4" fontWeight="bold" gutterBottom color="primary.main">
                                Contact Information
                            </Typography>
                            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                                <EmailIcon sx={{ mr: 1, color: "secondary.main" }} />
                                <Typography variant="body1">
                                    <strong>Email:</strong> support@greetingsgenerator.com
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                                <PhoneIcon sx={{ mr: 1, color: "secondary.main" }} />
                                <Typography variant="body1">
                                    <strong>Phone:</strong> +1 (123) 456-7890
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                                <LocationOnIcon sx={{ mr: 1, color: "secondary.main" }} />
                                <Typography variant="body1">
                                    <strong>Address:</strong> 123 Greetings Lane, Card City, GC 45678
                                </Typography>
                            </Box>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Follow us on social media for updates and more:
                            </Typography>
                            <Button
                                variant="outlined"
                                startIcon={<FacebookIcon />}
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                    borderRadius: "20px",
                                    mt: 2,
                                    display: "block",
                                    width: "100%",
                                }}
                                href="https://www.facebook.com"
                                target="_blank"
                            >
                                Facebook
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<TwitterIcon />}
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                    borderRadius: "20px",
                                    mt: 2,
                                    display: "block",
                                    width: "100%",
                                }}
                                href="https://www.twitter.com"
                                target="_blank"
                            >
                                Twitter
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContactPage;
