import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#780000", // Deep Red
      contrastText: "#FDF0D5", // Text on primary
    },
    secondary: {
      main: "#C1121F", // Bright Red
      contrastText: "#FDF0D5", // Text on secondary
    },
    background: {
      default: "#FDF0D5", // Light Cream
      paper: "#FFFFFF", // Card background
    },
    text: {
      primary: "#003049", // Dark Blue
      secondary: "#669BBC", // Sky Blue
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
});

export default theme;
