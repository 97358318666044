import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import theme from "./styles/theme";
import NavigationBar from "./components/Shared/NavigationBar";
import Footer from "./components/Shared/Footer";
import Home from "./pages/Home";
import WishFormPage from "./pages/WishFormPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                {/* Navigation Bar */}
                <NavigationBar />

                {/* Main Content */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100vh",
                        pb: 8, // Add padding to accommodate footer height
                    }}
                >
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/wishform" element={<WishFormPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                    </Routes>
                </Box>

                <Footer />
            </Router>
        </ThemeProvider>
    );
};

export default App;
