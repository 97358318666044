import axios from "axios";

export const generateWish = async (formData) => {
    try {
        const response = await axios.post(
            "https://kyib6rj0ch.execute-api.us-east-1.amazonaws.com/dev/generate-wish",
            formData,
            {
                headers: {
                    "x-api-key": "LvO3CqWWvz4sbYNDu4gsQ7CnkLWtaET2LeLzFqHe",
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data.body;
    } catch (error) {
        console.error("Error generating wish:", error);
        throw new Error("Failed to generate wish");
    }
};
