import React from "react";
import Slider from "react-slick";
import { Box, Typography, Paper, Button } from "@mui/material";

const WishFormSlider = ({ templates, onSelectTemplate }) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: "1200px",
                mx: "auto",
                mt: 4,
                ".slick-dots li button:before": {
                    fontSize: "12px",
                    color: "primary.main",
                },
                ".slick-dots li.slick-active button:before": {
                    color: "secondary.main",
                },
                ".slick-prev, .slick-next": {
                    zIndex: 1,
                    "&::before": {
                        color: "secondary.main",
                        fontSize: "24px",
                    },
                },
            }}
        >
            <Slider {...sliderSettings}>
                {templates.map((template) => (
                    <Box key={template.id} sx={{ px: 2 }}>
                        <Paper
                            elevation={4}
                            sx={{
                                overflow: "hidden",
                                borderRadius: "12px",
                                textAlign: "center",
                                boxShadow: "0px 5px 15px rgba(0,0,0,0.15)",
                                backgroundColor: "background.paper",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
                                },
                                transition: "transform 0.3s, box-shadow 0.3s",
                            }}
                        >
                            <Box
                                component="img"
                                src={template.imageUrl}
                                alt={template.title}
                                sx={{
                                    width: "100%",
                                    height: "220px",
                                    objectFit: "cover",
                                    borderRadius: "12px 12px 0 0",
                                }}
                            />
                            <Box sx={{ p: 3 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        mb: 1,
                                        color: "text.primary",
                                    }}
                                >
                                    {template.title}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        textTransform: "none",
                                        borderRadius: "20px",
                                        px: 4,
                                        py: 1,
                                        fontSize: "14px",
                                    }}
                                    onClick={() => onSelectTemplate(template)}
                                >
                                    Select Template
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default WishFormSlider;
