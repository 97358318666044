import React from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const NavigationBar = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "primary.main" }}>
            <Toolbar>
                {/* Logo and Title */}
                <Typography
                    variant="h5"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "primary.contrastText",
                    }}
                    onClick={() => handleNavigation("/")}
                >
                    Greetings & Wishes
                </Typography>

                {/* Navigation Links */}
                <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                    <Button
                        color="inherit"
                        sx={{
                            textTransform: "none",
                            mx: 1,
                            color: "primary.contrastText",
                            "&:hover": { color: "secondary.main" },
                        }}
                        onClick={() => handleNavigation("/")}
                    >
                        Home
                    </Button>
                    <Button
                        color="inherit"
                        sx={{
                            textTransform: "none",
                            mx: 1,
                            color: "primary.contrastText",
                            "&:hover": { color: "secondary.main" },
                        }}
                        onClick={() => handleNavigation("/about")}
                    >
                        About
                    </Button>
                    <Button
                        color="inherit"
                        sx={{
                            textTransform: "none",
                            mx: 1,
                            color: "primary.contrastText",
                            "&:hover": { color: "secondary.main" },
                        }}
                        onClick={() => handleNavigation("/contact")}
                    >
                        Contact
                    </Button>
                </Box>

                {/* Mobile Menu Icon */}
                <IconButton
                    edge="start"
                    sx={{ display: { xs: "flex", md: "none" }, color: "primary.contrastText" }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
